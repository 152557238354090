import { Button, Hidden, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import GreenDots from "@src/components/GreenDots";
import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { PhotosPageProps } from "@src/templates/PhotosPage";

import PhotoCard from "./PhotoCard";
import { styles } from "./PhotoGallery.styles";
import PhotoModal from "./PhotoModal";

export type PhotoGalleryProps = WithStyles<typeof styles> &
  WithPageData<PhotosPageProps> & {
    className?: string;
  };

export type PhotoGalleryState = {
  isAllPhotos: boolean;
  isOpen: boolean;
  index: number;
};

class PhotoGallery extends React.PureComponent<
  PhotoGalleryProps,
  PhotoGalleryState
> {
  public state: PhotoGalleryState = {
    isAllPhotos: false,
    isOpen: false,
    index: 0,
  };

  public render() {
    const {
      classes,
      className,
      pageData: {
        photosEvents: {
          frontmatter: { photosEventsList },
        },
      },
    } = this.props;
    const { isAllPhotos, isOpen, index } = this.state;

    return (
      <div className={cn(classes.container, className)}>
        <Hidden xsDown>
          <GreenDots className={classes.greenDots} horizontal />
        </Hidden>
        <Hidden smUp>
          <GreenDots className={classes.greenDots} />
        </Hidden>
        <div className={classes.photosWrapper}>
          {photosEventsList.map((photosEvent, i) => {
            return (
              <PhotoCard
                key={`photo-card-${i}`}
                image={photosEvent.photo}
                description={photosEvent.body}
                index={i}
                onClick={this.openModal}
                isHidden={!isAllPhotos && i > 3}
              />
            );
          })}
        </div>
        <PhotoModal
          isOpen={isOpen}
          onClose={this.closeModal}
          index={index}
          setActivePhotoIndex={this.setActivePhotoIndex}
          photosEventsList={photosEventsList}
        />
        <Button
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={isAllPhotos ? this.hidePhotos : this.showPhotos}>
          {isAllPhotos ? (
            <T message="Hide photos" />
          ) : (
            <T message="See all photos" />
          )}
        </Button>
      </div>
    );
  }

  private togglePhotos = (isAllPhotos: boolean) => {
    this.setState({ isAllPhotos });
  };
  private showPhotos = () => this.togglePhotos(true);
  private hidePhotos = () => this.togglePhotos(false);

  private toggleModal = (isOpen: boolean) => {
    this.setState({ isOpen });
  };
  private openModal = (index: number) => {
    this.toggleModal(true);
    this.setActivePhotoIndex(index);
  };
  private closeModal = () => this.toggleModal(false);

  private setActivePhotoIndex = (index: number) => {
    this.setState({ index });
  };
}

export default withPageData(withStyles(styles)(PhotoGallery));

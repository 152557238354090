import {
  Container,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import PhotoGallery from "./PhotoGallery";
import { styles } from "./PhotosTemplate.styles";

export type PhotosTemplateProps = WithStyles<typeof styles> & {};

class PhotosTemplate extends React.Component<PhotosTemplateProps> {
  public render() {
    const { classes } = this.props;

    return (
      <Container>
        <Typography variant="h2" className={classes.title}>
          <T message="Photos" />
        </Typography>
        <PhotoGallery className={classes.photoGallery} />
      </Container>
    );
  }
}

export default withStyles(styles)(PhotosTemplate);

export const addTransformationsToUrl = (
  url: string,
  transformations: {
    width?: number;
    height?: number;
    crop?: string;
    gravity?: string;
    zoom?: number;
  },
): string => {
  const transformString = Object.entries(transformations)
    .map(([key, value]) => `${key[0]}_${value}`)
    .join(",");
  return url.replace(/\/upload\//gim, `/upload/${transformString}/`);
};

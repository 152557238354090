import { createStyles, Theme } from "@material-ui/core";

const mdWidth = "calc((100vw - 24px - 80px) / 2)";
const smWidth = "calc(100vw - 64px)";
const xsWidth = "calc(100vw - 48px)";

export const styles = (theme: Theme) =>
  createStyles({
    cardContainer: {
      margin: theme.spacing(0, 1.5, 3),
      width: "548px",
      flex: "auto",
      boxShadow: theme.shadows[7],
      cursor: "pointer",
      textAlign: "left",
      maxWidth: "calc(50% - 24px)",
      [theme.breakpoints.down("md")]: {
        width: mdWidth,
      },
      "@media (max-width: 749px)": {
        width: smWidth,
        maxWidth: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: xsWidth,
      },
    },
    photoContainer: {
      height: "284px",
      "@media (max-width: 749px)": {
        height: `calc(${smWidth} / 670 * 428)`,
      },
      [theme.breakpoints.down("xs")]: {
        height: `calc(${xsWidth} / 312 * 240)`,
      },
    },
    image: {
      width: "100%",
      height: "100%",
      objectPosition: "center center",
      objectFit: "cover",
    },
  });

import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: "960px",
      height: "860px",
      maxHeight: "100%",
      [theme.breakpoints.down("xs")]: {
        height: "100%",
      },
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2, 0),
      },
    },
    dialogContent: {
      display: "flex",
      padding: theme.spacing(2, 5, 0),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 3),
      },
    },
    iconButton: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0, 2.5),
    },
    icon: {
      color: theme.palette.primary.main,
    },
    reversedIcon: {
      transform: "rotateY(180deg)",
    },
    disabledIcon: {
      color: theme.palette.text.disabled,
    },
    navIcon: {
      width: "36px",
      height: "36px",
      [theme.breakpoints.down("xs")]: {
        width: "24px",
        height: "24px",
      },
    },
    dialogActions: {
      justifyContent: "center",
      paddingBottom: theme.spacing(4.5),
      paddingTop: theme.spacing(2.5),
    },
  });

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  withMobileDialog,
  WithMobileDialog,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { ArrowBack, ArrowForward, Close } from "@material-ui/icons";
import cn from "classnames";
import React from "react";
import { withLocale, WithLocale } from "react-targem";

import { PhotosEvent } from "@src/templates/PhotosPage";

import { styles } from "./PhotoModal.styles";
import SwipableContent from "./SwipableContent";

export type PhotoModalProps = WithStyles<typeof styles> &
  WithMobileDialog &
  WithLocale & {
    photosEventsList: PhotosEvent[];
    isOpen: boolean;
    index: number;
    onClose(): void;
    setActivePhotoIndex(index: number): void;
  };

class PhotoModal extends React.PureComponent<PhotoModalProps> {
  public render() {
    const {
      photosEventsList,
      isOpen,
      onClose,
      classes,
      fullScreen,
      direction,
      index,
    } = this.props;
    const isLastItem = index === photosEventsList.length - 1;

    return (
      <Dialog
        open={isOpen}
        maxWidth={false}
        onClose={onClose}
        fullScreen={fullScreen}
        classes={{ paper: classes.paper }}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <IconButton className={classes.iconButton} onClick={onClose}>
            <Close className={classes.icon} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <SwipableContent
            photosEventsList={photosEventsList}
            index={index}
            setPreviousIndex={this.setPreviousIndex}
            setNextIndex={this.setNextIndex}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <IconButton
            disabled={index === 0}
            className={classes.iconButton}
            onClick={this.setPreviousIndex}>
            <ArrowBack
              className={cn(
                classes.icon,
                classes.navIcon,
                direction === "rtl" ? classes.reversedIcon : undefined,
                index === 0 ? classes.disabledIcon : undefined,
              )}
            />
          </IconButton>
          <IconButton
            disabled={isLastItem}
            className={classes.iconButton}
            onClick={this.setNextIndex}>
            <ArrowForward
              className={cn(
                classes.icon,
                classes.navIcon,
                direction === "rtl" ? classes.reversedIcon : undefined,
                isLastItem ? classes.disabledIcon : undefined,
              )}
            />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  }

  private setPreviousIndex = () => {
    const { setActivePhotoIndex, index } = this.props;
    setActivePhotoIndex(index - 1);
  };
  private setNextIndex = () => {
    const { setActivePhotoIndex, index } = this.props;
    setActivePhotoIndex(index + 1);
  };
}

export default withMobileDialog({ breakpoint: "xs" })(
  withLocale(withStyles(styles)(PhotoModal)),
);

import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      marginTop: theme.spacing(4),
    },
    photoGallery: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(25),
    },
  });

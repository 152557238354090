// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384

import PhotosPage from "@src/templates/PhotosPage";
export default PhotosPage;
import { graphql } from "gatsby";

export const pageQuery = graphql`
  query($initialLocale: String) {
    photosEvents: markdownRemark(
      fields: {
        slug: { regex: "/^/photos-events//" }
        locale: { eq: $initialLocale }
      }
    ) {
      frontmatter {
        photosEventsList {
          body
          photo
        }
      }
    }
  }
`;

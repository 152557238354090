import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";

import CmsContent from "@src/components/CmsContent";
import { addTransformationsToUrl } from "@src/utils/cloudinary";

import { styles } from "./PhotoCard.styles";

export type PhotoCardProps = WithStyles<typeof styles> & {
  image: string;
  description: string;
  index: number;
  isHidden: boolean;
  onClick(index: number): void;
};

class PhotoCard extends React.PureComponent<PhotoCardProps> {
  public render() {
    const { classes, description, image, isHidden } = this.props;

    if (isHidden) {
      return null;
    }
    return (
      <Card className={classes.cardContainer} onClick={this.handleClick}>
        <CardMedia className={classes.photoContainer}>
          <img
            className={classes.image}
            src={addTransformationsToUrl(image, {
              width: 640,
              height: 332,
              crop: "fill",
              gravity: "faces",
            })}
            alt="Press event photo"
          />
        </CardMedia>
        <CardContent>
          <Typography variant="body1">
            <CmsContent content={description} />
          </Typography>
        </CardContent>
      </Card>
    );
  }

  private handleClick = () => {
    const { onClick, index } = this.props;
    onClick(index);
  };
}

export default withStyles(styles)(PhotoCard);

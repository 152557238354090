import { Typography, WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import CmsContent from "@src/components/CmsContent";
import { PhotosEvent } from "@src/templates/PhotosPage";
import { addTransformationsToUrl } from "@src/utils/cloudinary";

import { styles } from "./SwipableContent.styles";

export type SwipableContentProps = WithStyles<typeof styles> & {
  photosEventsList: PhotosEvent[];
  index: number;
  setPreviousIndex(): void;
  setNextIndex(): void;
};

export type SwipableContentState = {
  coordinateX: number;
  coordinateY: number;
  swipeDirection?: "left" | "right";
};

class SwipableContent extends React.PureComponent<
  SwipableContentProps,
  SwipableContentState
> {
  public state: SwipableContentState = {
    coordinateX: 0,
    coordinateY: 0,
    swipeDirection: undefined,
  };
  public render() {
    const { photosEventsList, classes, index } = this.props;

    return (
      <div
        className={classes.contentWrapper}
        onTouchStart={this.handleStartSwipe}
        onTouchMove={this.detectSwipeDirection}
        onTouchEnd={this.handleEndSwipe}>
        {photosEventsList.map((photoEvent, i) => (
          <div
            key={i}
            className={cn(
              classes.content,
              i !== index && classes.hidden,
              i < index && classes.hiddenLeft,
              i > index && classes.hiddenRight,
            )}>
            <img
              className={classes.image}
              src={addTransformationsToUrl(photoEvent.photo, {
                width: 1024,
                crop: "fit",
              })}
              alt="Photo event photo"
            />
            <Typography variant="body1" className={classes.caption}>
              <CmsContent content={photoEvent.body} />
            </Typography>
          </div>
        ))}
      </div>
    );
  }

  private setCoordinate = (coordinateX: number, coordinateY: number) => {
    this.setState({ coordinateX, coordinateY });
  };

  private handleStartSwipe = (ev: React.TouchEvent) => {
    this.setCoordinate(ev.touches[0].clientX, ev.touches[0].clientY);
  };

  private detectSwipeDirection = (ev: React.TouchEvent) => {
    const { coordinateX, coordinateY } = this.state;
    const currentCoordinateX = ev.touches[0].clientX;
    const currentCoordinateY = ev.touches[0].clientY;

    if (!coordinateX && !coordinateY) {
      return;
    }
    const diffX: number = currentCoordinateX - coordinateX;
    const diffY: number = currentCoordinateY - coordinateY;

    if (Math.abs(diffY) > Math.abs(diffX)) {
      this.setSwipeDirection(undefined);
      return;
    }
    if (diffX > 0) {
      this.setSwipeDirection("left");
      return;
    }
    this.setSwipeDirection("right");
  };

  private setSwipeDirection = (
    swipeDirection: "left" | "right" | undefined,
  ) => {
    this.setState({ swipeDirection });
  };

  private handleEndSwipe = () => {
    const { swipeDirection } = this.state;
    const {
      index,
      setNextIndex,
      setPreviousIndex,
      photosEventsList,
    } = this.props;
    if (swipeDirection === "right" && index !== photosEventsList.length - 1) {
      setNextIndex();
      return;
    }
    if (swipeDirection === "left" && index !== 0) {
      setPreviousIndex();
      return;
    }
  };
}

export default withStyles(styles)(SwipableContent);

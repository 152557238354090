import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      textAlign: "center",
    },
    greenDots: {
      position: "absolute",
      width: "512px",
      right: theme.spacing(-5),
      top: theme.spacing(-5),
      [theme.breakpoints.down("md")]: {
        left: "70%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        height: "446px",
        right: theme.spacing(-20),
      },
    },
    photosWrapper: {
      margin: theme.spacing(0, -1.5),
      display: "flex",
      flexWrap: "wrap",
    },
    button: {
      margin: "auto",
      marginTop: theme.spacing(4.5),
    },
  });

import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    contentWrapper: {
      flex: "auto",
      width: "inherit",
      height: "inherit",
      position: "relative",
      overflow: "hidden",
    },
    content: {
      position: "absolute",
      backgroundColor: theme.palette.common.white,
      width: "100%",
      height: "100%",
      left: 0,
      transition: "left 1s, opacity 1s, z-index 1s",
      zIndex: 1,
      opacity: 1,
      display: "flex",
      flexDirection: "column",
    },
    hidden: {
      zIndex: 0,
      opacity: 0,
    },
    hiddenLeft: {
      left: "-100%",
    },
    hiddenRight: {
      left: "100%",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "85%",
      objectFit: "contain",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "70%",
      },
    },
    caption: {
      marginTop: theme.spacing(3),
      textAlign: "left",
      flex: "none",
    },
  });

import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageWithImagesProps } from "@src/types";
import { t } from "@src/utils/translations";

import PhotosTemplate from "./PhotosTemplate";

export type PhotosEvent = {
  body: string;
  photo: string;
};

export type PhotosPageProps = PageWithImagesProps<
  "",
  {
    photosEvents: {
      frontmatter: {
        photosEventsList: PhotosEvent[];
      };
    };
  }
>;

class PhotosPage extends React.Component<PhotosPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("Photos")}
          description={t(
            "Trucknet participates in different events such as the Cop21 convention in Paris 2015, the Technology week 'Viva Tech' etc.",
          )}
          keywords={[t("photos")]}
        />
        <PhotosTemplate />
      </IndexLayout>
    );
  }
}

export default PhotosPage;
